"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatForDateControl = exports.addDays = exports.getSpecificTimeZoneDateString = exports.utcToISODateString = exports.sortDatesAsc = void 0;
var date_fns_1 = require("date-fns");
exports.sortDatesAsc = function (x, y) {
    var a = new Date(x).getTime(), b = new Date(y).getTime();
    return a - b;
};
exports.utcToISODateString = function (value) {
    if (value === "" || value === null || value === undefined) {
        return "";
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    try {
        return inputDate.toISOString().slice(0, 10);
    }
    catch (ex) {
        return "";
    }
};
exports.getSpecificTimeZoneDateString = function (value, timeZone) {
    if (value === "" || value === null || value === undefined) {
        return "";
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    try {
        return inputDate.toLocaleDateString("en-US", { timeZone: timeZone });
    }
    catch (ex) {
        return "";
    }
};
exports.addDays = function (value, days) {
    if (value === "" || value === null || value === undefined) {
        return null;
    }
    var inputDate = typeof value === "string" ? new Date(value) : value;
    var date = new Date(inputDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
exports.formatForDateControl = function (date) {
    if (!date)
        return "";
    var _a = date.substr(0, 10).split("-"), year = _a[0], month = _a[1], day = _a[2];
    if (parseInt(year) > 999) {
        return date_fns_1.format(new Date(year, month - 1, day), "yyyy-MM-dd");
    }
    return date;
};
