import { dateUtils } from "oda-shared";
import { format } from "date-fns";

export const getFiscalYearFromDate = (input: Date) => {
  const inputDate = new Date(input);
  const year = inputDate.getFullYear();

  const fiscalYearCutoff = new Date(year, 5, 30);

  if (inputDate > fiscalYearCutoff) {
    //first Half
    return `${year}-${year + 1}`;
  }
  //second Half
  else return `${year - 1}-${year}`;
};

export const inputFieldDateFormat = (date: Date | null | undefined): string => {
  return dateUtils.utcToISODateString(date);
};

export const formatNullableDate = (date: Date | string | null | undefined): string => {
  if (date === undefined || date === null) {
    return "N/A";
  }

  const asDate = typeof date === "string" ? new Date(date) : date;
  return format(asDate, "MM/dd/yyyy h:mm a");
};

export const removeTimeFromDate = (date: Date) => {    
  let val = new Date(date);
  val.setHours(0,0,0,0);  
  return val;
}

export const formatDateWithNoTimeZone = (date: Date | string | null | undefined): string => {
  if (date === undefined || date === null || date === "") {
    return "N/A";
  }  
  const asDate = typeof date === "string" ? new Date(`${date}`) : date;  
  try {    
    return format(asDate, "MM/dd/yyyy");
  } catch (e) {
    console.log(`Date: ${date}, asDate: ${new Date(date)}`);    
    console.log(e);
    return "N/A";
  }
};
