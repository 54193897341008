import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccountRenewalsNeededRow, IPermitRenewalDate, IAnnualRenewalNoticesHistory } from "oda-shared";
import * as renewalDateApi from "../../apis/permitRenewalDateApi";
import { AnnualNoticesInfo } from "../../apis/permitRenewalDateApi";

type SliceState = {
  permitRenewalDate: IPermitRenewalDate;
  isLoaded: boolean;
  odaAccountsForRenewal: IAccountRenewalsNeededRow[];
  annualNoticesInfo: AnnualNoticesInfo;
  isAnnualNoticesInfoLoaded: boolean;
  annualNoticesHistory: IAnnualRenewalNoticesHistory[];
};

const permitRenewalDateSlice = createSlice({
  name: "permitRenewalDate",
  initialState: {
    isLoaded: false,
    permitRenewalDate: {},
    odaAccountsForRenewal: [],
    annualNoticesInfo: {},
    isAnnualNoticesInfoLoaded: false,
    annualNoticesHistory: [],
  } as unknown as SliceState,
  reducers: {
    updateRenewalDate: (state: SliceState, action: PayloadAction<IPermitRenewalDate>) => {
      state.permitRenewalDate = action.payload;
      state.isLoaded = true;
      return state;
    },
    getRenewalDate: (state: SliceState, action: PayloadAction<IPermitRenewalDate>) => {
      state.permitRenewalDate = action.payload;
      state.isLoaded = true;
      return state;
    },
    updateOdaAccountsForRenewal: (state: SliceState, action: PayloadAction<IAccountRenewalsNeededRow[]>) => {
      state.odaAccountsForRenewal = action.payload;
      return state;
    },
    updateAnnualNoticesInfo: (state: SliceState, action: PayloadAction<AnnualNoticesInfo>) => {
      state.annualNoticesInfo = action.payload;
      return state;
    },
    updateIsAnnualNoticesInfoLoaded: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
      return state;
    },
    updateIsLoaded: (state: SliceState, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
      return state;
    },
    updateAnnualNoticesHistory: (state: SliceState, action: PayloadAction<IAnnualRenewalNoticesHistory[]>) => {
      state.annualNoticesHistory = action.payload;
      return state;
    },
  },
});

export const {
  getRenewalDate,
  updateRenewalDate,
  updateOdaAccountsForRenewal,
  updateIsLoaded,
  updateAnnualNoticesHistory,
  updateAnnualNoticesInfo,
  updateIsAnnualNoticesInfoLoaded,
} = permitRenewalDateSlice.actions;
export default permitRenewalDateSlice.reducer;

export const fetchAnnualNoticesHistory = () => async (dispatch: any) => {
  renewalDateApi
    .getAnnualNoticesHistory()
    .then((notices: IAnnualRenewalNoticesHistory[]) => dispatch(updateAnnualNoticesHistory(notices)))
    .catch((error: any) => {
      console.log(error);
    });
};

export const fetchRenewalDate = () => async (dispatch: any) => {
  renewalDateApi
    .getRenewalDate()
    .then((renewalDate: IPermitRenewalDate) => {
      dispatch(getRenewalDate(renewalDate));
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getOdaAccountsForRenewal = () => async (dispatch: any) => {
  try {
    dispatch(updateIsLoaded(true));
    const accountsWithRenewals = await renewalDateApi.getAccountsWithRenewalNeeded();
    dispatch(updateOdaAccountsForRenewal(accountsWithRenewals));
    dispatch(updateIsLoaded(false));
  } catch (error) {
    dispatch(updateIsLoaded(false));
    console.log(error);
  }
};
export const fetchAnnualNoticesInfo = () => async (dispatch: any) => {
  try {
    dispatch(updateIsAnnualNoticesInfoLoaded(false));
    const noticesInfo = await renewalDateApi.getAnnualNoticesInfo();
    dispatch(updateAnnualNoticesInfo(noticesInfo));
    dispatch(updateIsAnnualNoticesInfoLoaded(true));
  } catch (error) {
    dispatch(updateIsAnnualNoticesInfoLoaded(false));
    console.log(error);
  }
};

export const updateDate =
  (incomingRenewalDate: Date, successCallback: () => void, failureCallback: () => void) => async (dispatch: any) => {
    renewalDateApi
      .updateRenewalDate(incomingRenewalDate)
      .then((renewalDate: IPermitRenewalDate) => {
        dispatch(updateRenewalDate(renewalDate));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        failureCallback();
      });
  };

export const updateLastTransferDate =
  (
    incomingLastTransferDate: Date,
    renewWithoutPetitionDate: Date,
    successCallback: () => void,
    failureCallback: () => void,
  ) =>
  async (dispatch: any) => {
    renewalDateApi
      .updateLastTransferDate(incomingLastTransferDate, renewWithoutPetitionDate)
      .then((renewalDate: IPermitRenewalDate) => {
        dispatch(updateRenewalDate(renewalDate));
        successCallback();
      })
      .catch((error: any) => {
        console.log(error);
        failureCallback();
      });
  };
