import axios from "axios";
import {
  IAccountRenewalsNeededRow,
  IPermitRenewalDate,
  IAnnualRenewalSchedule,
  IAnnualRenewalNoticesHistory,
} from "oda-shared";

export type AnnualNoticesInfo = {
  annualSchedules: IAnnualRenewalSchedule[];
  nonpaymentViolationsCount: number;
  finalViolationsCount: number;
};

const renewalDateBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/permitRenewalDate`;
const transferDateBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/lastTransferDate`;
const renewalsNeededBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/accountsWithRenewalNeeded`;
const runAnnualSummaryUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/runAnnualSummary`;
const annualRenewalsBaseUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/annualRenewals`;

export const updateRenewalDate = async (renewalDate: Date): Promise<IPermitRenewalDate> => {
  const response = await axios.patch(renewalDateBaseUrl, { renewalDate });
  return response.data;
};

export const updateLastTransferDate = async (
  lastTransferDate: Date,
  lastDateToRenewWithoutPetition: Date,
): Promise<IPermitRenewalDate> => {
  const response = await axios.patch(transferDateBaseUrl, { lastTransferDate, lastDateToRenewWithoutPetition });
  return response.data;
};

export const getRenewalDate = async (): Promise<IPermitRenewalDate> => {
  const response = await axios.get(renewalDateBaseUrl);
  return response.data;
};

export const getAnnualNoticesInfo = async (): Promise<AnnualNoticesInfo> => {
  const response = await axios.get(`${annualRenewalsBaseUrl}/annual-notices-info`);
  return response.data;
};

export const getAnnualNoticesSchedule = async (): Promise<IAnnualRenewalSchedule> => {
  const response = await axios.get(`${annualRenewalsBaseUrl}/annual-schedule`);
  return response.data;
};


export const getAnnualNoticesHistory = async (): Promise<IAnnualRenewalNoticesHistory[]> => {
  const response = await axios.get(`${annualRenewalsBaseUrl}/annual-notices-history`);
  return response.data;
};

export const getAccountsWithRenewalNeeded = async (): Promise<IAccountRenewalsNeededRow[]> => {
  const response = await axios.get(renewalsNeededBaseUrl);
  return response.data;
};

export const runAnnualSummary = async () => {
  const response = await axios.get(runAnnualSummaryUrl);
  return { status: response.status, message: response.data.message };
};

export const getAllAnnualSupplementPdfUri = () =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/allAnnualSupPdf`;

export const getAnnualSummaryPdfUri = () => `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/annualSummary`;

export const getRenewalNoticesByNoticeTypeUri = (noticeType: string) =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/annual-notices/${noticeType}`;

export const runAnnualRenewalReport = () =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/annual-renewals-report`;

export const runMassRenewalNotices = async (renewalNoticeSelection: string) => {
  const response = await axios.get(
    `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/mass-renewal-notices/${renewalNoticeSelection}`,
  );
  return { status: response.status, message: response.data.message };
};
export const runNonpaymentViolationNotices = () =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/nonpayment-violation-notices`;

export const runFinalViolationNotices = () =>
  `${fdot.process.env.BACKEND_SERVER_HOST}/api/download/final-violation-notices`;

export const updateScheduleByType = async (type: string) => {
  const response = await axios.patch(`${annualRenewalsBaseUrl}/update-schedule/${type}`);
  return { status: response.status, message: response.data.message };
};
